import React from "react";
import '../styles.scss';
import ReactGA from 'react-ga';
import {List, ListItem} from '@material-ui/core';

class Slide extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      highlight: props.slideNumber
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({highlight: nextProps.slideNumber});
  }

  componentDidUpdate = () => {
    let container = document.getElementsByClassName('slide-nav')[0];
    let element = document.getElementsByClassName('selected')[0];
    let fullView = true;
    if(container && element){
      this.isSlideInView(container, element, fullView);
      this.bringSlideInView(container, element);
    }
  }

  presentSlide = (key) => {
    this.setState({highlight: key});
    this.props.onClick(key);
  }

  isSlideInView(container, element, fullView) {
    //Get container properties
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;
    //Get element properties
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;
    //Check if in view    
    let isTotal = (eTop >= cTop && eBottom <= cBottom);
    let isPartial = fullView && (
      (eTop < cTop && eBottom > cTop) ||
      (eBottom > cBottom && eTop < cBottom)
    );
    //Return outcome
    return  (isTotal  || isPartial);
  }

  bringSlideInView(container, element) {
    //Determine container top and bottom
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;
    //Determine element top and bottom
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight + 10;
    //Check if out of view
    if (eTop < cTop) {
      container.scrollTop -= (cTop - eTop);
    }
    else if (eBottom > cBottom) {
      container.scrollTop += (eBottom - cBottom);
    }
  }

  createSlides(){
    let allSlides = [];
    for(let i = 0; i < this.props.slideImages.length; i++){
      allSlides.push(
        <ListItem button key={i} className={`slideListButton ${i === this.state.highlight ? 'selected': ''}`} onClick={() => this.presentSlide(i)}>
          <div className={`slide-number-image ${i === this.state.highlight ? 'active': ''}`}>
            <div className="slide-number">{i+1}</div>
              <div className="slide-image">
              <img src={this.props.slideImages[i]} alt=""/>
            </div>
          </div>
        </ListItem>
      );
    }
    return allSlides;
  }

  render(){
    return(
      <List component="nav" aria-label="navigate slides" className="slide-nav">
        {this.createSlides()}
      </List>
    )
  }
}

export default Slide;