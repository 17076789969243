import React from 'react';
import {Link} from "react-router-dom";
import Header from '../components/Header';
import PresentationArea from './../components/PresentationArea';
import './../styles.scss';
import {Grid, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button, Typography} from '@material-ui/core';

class Upload extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            key: '',
            images: [],
            showProgressBar: false,
            openUploadDialog: true,
            openSuccessDialog: false
        };
        this.submitFile = this.submitFile.bind(this);
    }

    componentDidMount(){
    }

    submitFile(){
        this.setState({openUploadDialog: false, showProgressBar: true});

        const fileInput = document.querySelector('input[type="file"]');
        const formData = new FormData();
        const UPLOAD_DOC = "https://playground-1-189819.appspot.com/pdf2image/image";
        formData.append('file', fileInput.files[0]);
        console.log("Converting...");
        
        fetch(UPLOAD_DOC, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            this.setState({openSuccessDialog: true, showProgressBar: false});
            console.log('Success');
            this.setState({images: data.files.slice(0), key: data.key});
            document.getElementById('linkInput').value = window.location.origin + '/viewdoc/?id=' + this.state.key;
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    copyLink(){
        let copyText = document.getElementById("linkInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    

    render() {
        return (
            <Grid container spacing={0} className="height100">
                <Header/>
                <Grid item className="page uploader">
                    <Dialog open={this.state.openUploadDialog} onClose={this.uploadDialogClose} aria-labelledby="upload-dialog-title" disableBackdropClick={true} disableEscapeKeyDown={true} fullWidth={true}>
                        <DialogTitle id="upload-doc-title">Upload document</DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Select the file you wish to upload
                            </Typography>
                            <input type="file" onChange={this.submitFile} style={{ display: 'none' }} id="fileInput"/>
                            <label htmlFor="fileInput">
                                <Button variant="contained" component="span" color="primary" disableElevation>
                                    Select and Upload
                                </Button>
                            </label> 
                        </DialogContent>
                    </Dialog>
                    
                    <div className="progress" hidden={!this.state.showProgressBar}>
                        <div className="progress-bar"></div>
                    </div>

                    <Dialog open={this.state.openSuccessDialog} onClose={this.successDialogClose} aria-labelledby="success-dialog-title" disableBackdropClick={true} disableEscapeKeyDown={true} fullWidth={true}>
                        <DialogTitle id="doc-uploaded-title">Successfully uploaded!</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText>
                                <Typography gutterBottom>
                                    Share the link below:
                                </Typography>
                                <Typography component={'div'} gutterBottom>
                                    <Link component={'span'} to={{
                                        pathname: '/',
                                        search: `viewdoc=${this.state.key}`,
                                    }}>{window.location.origin + '/viewdoc/?id=' + this.state.key}</Link>
                                </Typography>
                                <input className="view-hidden" type="text" id="linkInput"/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={this.copyLink} color="primary" disableElevation>
                                Copy Link
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        );
    }
}

export default Upload;