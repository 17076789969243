import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import slideDocReducer from './slideDocReducer';

const rootReducer = combineReducers({
    slideDocReducer: slideDocReducer,
    loginReducer: loginReducer
});

export default rootReducer;
