import * as actionType from '../actions/actionTypesConstants';

const initialState = {
    id: '',
    token: ''
}

export default function loginReducer(state = initialState, action){
    switch(action.type){
        case actionType.LOGIN_SUCCESS: {
            return Object.assign({}, state)
        }
        default: 
            return state
    }
}