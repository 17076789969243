import * as actionType from '../actions/actionTypesConstants'

const initialState = {
    images: [],
    emailAddress: '',
    id: 0,
    showProgressBar: false
}

export default function slideDocReducer(state = initialState, action){
    //degubber;
    switch(action.type){
        case actionType.SHOW_SPINNER: {
            return Object.assign({}, state, {showProgressBar: action.value})
        }
        case actionType.LOAD_SLIDES_SUCCESS: {
            return Object.assign({}, state, {images: action.data.slice(0), emailAddress: action.emailAddress, showProgressBar: false})
        }
        case actionType.NEXT_SLIDE: {
            let slideNumber = state.id;
            slideNumber++;
            if (slideNumber < state.images.length - 1){
                return Object.assign({}, state, {id: slideNumber});
            }
            else if(slideNumber === state.images.length - 1){
                return Object.assign({}, state, {id: state.images.length-1});
            }
            else{
                return state;
            }
        }
        case actionType.PREV_SLIDE: {
            let slideNumber = state.id;
            slideNumber--;
            if (slideNumber > 0){
                return Object.assign({}, state, {id: slideNumber});
            }
            else if(slideNumber === 0){
                return Object.assign({}, state, {id: 0});
            }
            else{
                return state;
            }
        }
        case actionType.SHOW_SLIDE: {
            return Object.assign({}, state, {id: action.id})
        }
        default:
            return state
    }
}
