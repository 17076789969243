import React from "react";
import Slide from './Slide';
import '../styles.scss';
import Icon from '@material-ui/core/Icon';
import {Grid, Hidden, Drawer} from '@material-ui/core';

class PresentationArea extends React.Component{
  constructor(props){
    super(props);
    // this.showSlide = this.showSlide.bind(this);
    this.state = {
      key: 0,
      open: false
    }
  }

  toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({open: open });
  };

  showSlideWithKey = (imageKey) => {
    this.props.showSlide(imageKey);
    if(this.state.open){
      this.setState({open: false})
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({key: nextProps.slideNumber})
  }

  emitNav = (event, direction) => {
    if(event){
      switch (event.keyCode){
        case 37: {
          this.props.navdirection('prev');
          break;
        }
        case 39: {
          this.props.navdirection('next');
          break;
        }
        default: {
          
        }
      }
    }
    else{
      this.props.navdirection(direction);
    }
  }

  render(){
    const mainSlideStyles = {
      backgroundImage: `url(${encodeURI(this.props.slides[this.state.key])})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    }
    return(
      <div className="presentation-area" tabIndex="0" onKeyDown={(event) => this.emitNav(event, null)}>
        <Grid container spacing={0} justify="space-between" alignItems="center" className="height100">
          <Hidden smUp>
            <Drawer open={this.state.open} onClose={this.toggleDrawer(false)} className="slides-drawer">
              <Slide slideImages={this.props.slides} slideNumber={this.state.key} onClick={this.showSlideWithKey}/>
            </Drawer>
          </Hidden>
          <Hidden xsDown>
            <Grid item sm={2} className="height100">
              <Slide slideImages={this.props.slides} slideNumber={this.state.key} onClick={this.showSlideWithKey}/>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={10} className="slide-container">
            <div className="slide" style={mainSlideStyles}></div>
          </Grid>
        </Grid>
        <div className="presentation-tools">
          <Hidden smUp>
            <button aria-label="Show Slides" onClick={this.toggleDrawer(true)}><Icon>menu_open</Icon></button>
          </Hidden>
          <button aria-label="Previous Slide" disabled={this.props.disablePrev ? 'disabled' : null} onClick={() => this.emitNav(null, 'prev')} className="left-arrow"><Icon>arrow_right_alt</Icon></button>
          <button aria-label="Next Slide" disabled={this.props.disableNext ? 'disabled': null} onClick={() => this.emitNav(null, 'next')}><Icon>arrow_right_alt</Icon></button>
        </div>
      </div>
    );
  }
}

export default PresentationArea;