import React from 'react';
import './../styles.scss';

class GenericNotFound extends React.Component{
    render() {
        return (
            <div className="page notfound">
                <h1>Something's not right</h1>
                <p>We couldn't find anything over there!</p>
            </div>
        );
    }
}

export default GenericNotFound;