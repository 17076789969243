import React from "react";
import '../styles.scss';
import logo from '../images/logo.png';

const Header = (props) => {
  return(
    <div className="toolbar">
      <img src={logo} alt="TM7" width="100px"/>
    </div>
  );
}

export default Header;