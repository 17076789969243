import {handleResponse, handleError} from './apiUtils';

export function getDocument(emailAddress){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let key = params.get('id');

    const VIEW_DOC = "https://playground-1-189819.appspot.com/pdf2image/findLinks?key=" + key + "&email=" + emailAddress;

    console.log("Fetching...");
    return fetch(VIEW_DOC, {
            method: 'GET'
        })
        .then(handleResponse)
        .catch(handleError);
}