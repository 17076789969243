import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import { dark } from '@material-ui/core/styles/createPalette';

const store = configureStore();
const trackingId = 'UA-159220389-2';

ReactGA.initialize(trackingId, {
    debug: true
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#aed581',
            main: '#4caf50',
            dark: '#2e7d32',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffe082',
            main: '#ffc107',
            dark: '#ff8f00',
            contrastText: '#000',
        },
        type: 'dark',
    },
    status: {
        danger: 'orange',
    },
  });

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
