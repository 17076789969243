import * as actionType from './actionTypesConstants';
import * as getSlidesApi from '../../api/getSlidesApi';

export function showSpinner(value){
    return {type: actionType.SHOW_SPINNER, value}
}

export function goToNextOrPrevSlide(direction){
    //degubber;
    if(direction === 'next'){
        return {type: actionType.NEXT_SLIDE}
    }
    else{
        return {type: actionType.PREV_SLIDE}
    }
}

export function showSlide(id){
    return {type: actionType.SHOW_SLIDE, id}
}

export function loadSlidesSuccess(data, emailAddress){
    return {type: actionType.LOAD_SLIDES_SUCCESS, data, emailAddress}
}

export function loadSlides(emailAddress){
    return function(dispatch){
        return getSlidesApi.getDocument(emailAddress).then(data => {
            dispatch(loadSlidesSuccess(data, emailAddress));
        }).catch(error => {
            throw error;
        })
    }
}
