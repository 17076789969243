import React from 'react';
import Header from '../components/Header';
import PresentationArea from './../components/PresentationArea';
import './../styles.scss';

class Analytics extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>Coming Soon... </div>
        )
    }
}

export default Analytics;