import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as loginActions from '../redux/actions/loginActions';
import Header from '../components/Header';
import {Card, CardContent, Typography, Button, TextField, Grid, Collapse} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';

export const Login = (props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const alertStyles = {
        margin: '0 12px'
    }

    useEffect(() => {
        if(localStorage.getItem('user')){
            history.push('/upload');
        }
    });
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let username = document.getElementById('username').value;
        let password = document.getElementById('password').value;
        props.signInActions
            .loginAttempt(username, password)
            .then(data => {
                history.push('/upload');
            })
            .catch(error => {
                setOpen(true);
            });
    }

    return(
        <Grid container spacing={0} className="height100">
            <Header/>
            <Grid item xs={12} className="page">
                <Card variant="outlined">
                    <CardContent>
                        <form id="appLogin" onSubmit={(event) => handleSubmit(event)}>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h5" component="h2">
                                        Login
                                    </Typography>
                                </Grid>
                                <Collapse in ={open}>
                                    <Alert severity="error" style={alertStyles}>The Email or Password is incorrect!</Alert>
                                </Collapse>
                                <Grid item className="mb20">
                                    <TextField
                                        id="username"
                                        autoFocus
                                        margin="dense"
                                        className="mb20"
                                        label="Email Address"
                                        variant="filled"
                                        type="email"
                                        fullWidth
                                    />
                                    <TextField
                                        id="password"
                                        margin="dense"
                                        className="mb20"
                                        label="Password"
                                        variant="filled"
                                        type="password"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Button form="appLogin" type="submit" variant="contained" color="primary" disableElevation>Login</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

Login.propTypes = {
    signInActions: PropTypes.object.isRequired
}

function mapStateToProps(state){
    //degubber;
    const {id, token} = state.loginReducer;
    return {
        id: id,
        token: token
    }
}

function mapDispatchToProps(dispatch){
    return {
        signInActions: bindActionCreators(loginActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);