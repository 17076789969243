import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom';
import Home from './pages/Home';
import Upload from './pages/Upload';
import Analytics from './pages/Analytics';
import GenericNotFound from './pages/GenericNotFound';
import Login from './pages/Login';
import {PrivateRoute} from './_components/PrivateRoute';

export default function App() {
  return (
    <Router>
      <div className="page">
        <nav>
          {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/upload">Upload</Link>
            </li>
            <li>
              <Link to="/analytics">Analytics</Link>
            </li>
          </ul> */}
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/viewdoc">
            <Home />
          </Route>
          <PrivateRoute exact path="/upload" component={Upload} />
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/analytics">
            <Analytics />
          </Route>
          <Route path="/">
            <Login />
          </Route>
          <Route path="*">
            <GenericNotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
