import {handleResponse, handleError} from './apiUtils';
import {loginConstants} from './loginConstants';

export function login(username, password){
    console.log("Attempting to Login...");

    //DUMMY AUTH. REPLACE WITH FETCH
    return new Promise((resolve, reject) => {
            if(username === loginConstants.FAKE_USERNAME && password === loginConstants.FAKE_PASSWORD){
                let responseJson = {
                    id: username,
                    token: 'fake-jwt-token'
                };
                resolve({ ok: true, json: () => Promise.resolve(JSON.stringify(responseJson)) });
            }
            else{
                reject('Username or Password is incorrect');
            }
        })
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', user);
        })
        .catch(handleError);
}