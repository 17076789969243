import * as actionType from './actionTypesConstants';
import * as loginApi from '../../api/loginApi';

export function showSpinner(value){
    return {type: actionType.SHOW_SPINNER, value}
}

export function loginSuccess(user){
    return {type: actionType.LOGIN_SUCCESS, user}
}

export function loginAttempt(username, password){
    return function(dispatch){
        return loginApi.login(username, password).then(user => {
            dispatch(loginSuccess(user));
        }).catch(error => {
            throw error;
        })
    }
}
