import React from 'react';
import Header from '../components/Header';
import PresentationArea from './../components/PresentationArea';
import { connect } from 'react-redux';
import './../styles.scss';
import * as slideDocActions from '../redux/actions/slideDocActions';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import ReactGA from 'react-ga';
import {Grid, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button} from '@material-ui/core';

class Home extends React.Component{
    constructor(props) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let docKey = params.get('id');
        this.state = {
            emailVerified: false,
            emailError: false,
            disablePrev: true,
            disableNext: false,
            openDialog: true,
            docKey: docKey
        };
    }

    componentDidMount = async () => {
        ReactGA.ga('send', 'pageview', this.state.docKey+'/emailverification');
        let email = localStorage.getItem('email');
        if(email !== null){
            await this.setState({
                emailVerified: true,
                openDialog: false
            });
            this.getSlides(email);
            this.setUserGA(email);
        }
    }

    submitEmail = async () => {
        let email = document.getElementById('emailInput').value;
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(email.length && regex.test(String(email).toLowerCase())){
            await this.setState({
                emailVerified: true,
                openDialog: false
            });
            localStorage.setItem('email', email);
            this.getSlides(email);
            this.props.slideActions.showSpinner(true);
            this.setUserGA(email);
        }
        else{
            this.setState({emailError: true});
        }
    }

    setUserGA = (email) => {
        ReactGA.set({userId: email, key: this.state.docKey});
        ReactGA.ga('set', 'page', '/viewdoc/?id=' + this.state.docKey);
        ReactGA.ga('send', 'pageview');
    }

    getSlides = (email) => {
        this.props.slideActions.loadSlides(email).catch(error => {
            alert('Fetching slides failed ' + error);
        });
    }

    navPrev = async () => {
        //degubber;
        await this.props.slideActions.goToNextOrPrevSlide('prev');
        ReactGA.ga('send', 'pageview', this.state.docKey+'/slide-'+(this.props.id+1));
        this.nextAndPrevButtons();
    }

    navNext = async () => {
        //degubber;
        await this.props.slideActions.goToNextOrPrevSlide('next');
        ReactGA.ga('send', 'pageview', this.state.docKey+'/slide-'+(this.props.id+1));
        this.nextAndPrevButtons();
    }

    buttonNavigate = (navDirection) => {
        if(navDirection === 'prev'){
            this.navPrev();
        }
        else{
            this.navNext();
        }
    }

    showSlide = async (imageKey) => {
        await this.props.slideActions.showSlide(imageKey);
        ReactGA.ga('send', 'pageview', this.state.docKey+'/slide-'+(imageKey+1));
        this.nextAndPrevButtons();
    }

    nextAndPrevButtons = () => {
        if (this.props.id >= 1 && this.props.id <= this.props.images.length - 2){
            this.setState({disablePrev: false, disableNext: false});
        }
        else if(this.props.id === 0){
            this.setState({disablePrev: true, disableNext: false});
        }
        else if(this.props.id === this.props.images.length - 1){
            this.setState({disablePrev: false, disableNext: true});
        }
    }

    dialogOpen = () => {
        this.setState({openDialog: true});
    }

    dialogClose = () => {
        this.setState({openDialog: false});
    }

    render() {
        const progressShowHide = this.props.showProgressBar ? {} : {display: 'none'};
        const presentationShowHide = (this.props.images.length && this.state.emailVerified && !this.props.showProgressBar) ? {} : {display: 'none'};
        const dialogActions = {
            padding: '12px 24px'
        }
        return (
            <Grid container spacing={0} className="height100">
                <Header/>
                <Grid item className="page viewer">
                    <div className="progress" style={progressShowHide}>
                        <div className="progress-bar"></div>
                    </div>
                    <Dialog open={this.state.openDialog} onClose={this.dialogClose} aria-labelledby="email-dialog-title" disableBackdropClick={true} disableEscapeKeyDown={true} fullWidth={true}>
                        <DialogTitle id="email-dialog-title">Verification</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please enter a valid email address for verification:
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                className={`${this.state.emailError ? 'errorStyle' : ''}`} id="emailInput"
                                label="Email Address"
                                type="email"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions style={dialogActions}>
                            <Button variant="contained" onClick={this.submitEmail} color="primary" disableElevation>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="presentation-container" style={presentationShowHide}>
                        <Grid container spacing={0} className="height100">
                            <Grid item xs={12} className="height100">
                                <PresentationArea
                                    key={this.props.images.length}
                                    slides={this.props.images}
                                    nav={this.navigate} 
                                    slideNumber={this.props.id} 
                                    showSlide={this.showSlide} 
                                    navdirection={this.buttonNavigate} 
                                    disablePrev={this.state.disablePrev} 
                                    disableNext={this.state.disableNext} 
                                    slideId={this.props.id}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

Home.propTypes = {
    slideActions: PropTypes.object.isRequired
}

function mapStateToProps(state){
    //degubber;
    const {images, emailAddress, id, showProgressBar} = state.slideDocReducer;
    return {
        images: images,
        emailAddress: emailAddress,
        id: id,
        showProgressBar: showProgressBar
    }
}

function mapDispatchToProps(dispatch){
    return {
        slideActions: bindActionCreators(slideDocActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);